// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const relevantID = {
  clientID: null,
  scriptName: 'RelevantId v4.0',
  scriptID: 'relevantid',
  scriptSrc: 'https://d3op16id4dloxg.cloudfront.net/RelevantID4.js'
};
const imperiumCSO = {
  clientID: null,
  scriptName: 'Imperium CSO v2.0',
  scriptID: 'imperium-cso',
  scriptSrc: 'https://d3op16id4dloxg.cloudfront.net/CSOWrapperAjax2.js'
};


export const environment = {
  production: false,
  relevantID,
  imperiumCSO
};
/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
