import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StudiesState } from './studies.reducer';




export const studiesStoreProperty = 'data-insights';


export const selectDataInsightState = createFeatureSelector<StudiesState>(studiesStoreProperty);
export const selectDataInsight = createSelector(selectDataInsightState, ({dataInsights}) => dataInsights);
