import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { TimeIcons, TimeIconsType, TimeIconType } from '@tr-common';

@Component({
  selector: 'lib-icon-time',
  template: `
    <span class="time-mat-icon"><mat-icon [svgIcon]="icons[mode]"></mat-icon></span>
    <span class="time-content"><ng-content></ng-content></span>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./icon-time.component.scss']
})
export class IconTimeComponent {
  @HostBinding('class') @Input() mode: TimeIconType = TimeIcons.approximate;
  icons: TimeIconsType = {
    approximate: 'time',
    'approximate-banner': 'time',
    launchOn: 'calendar-ok-grey',
    launchGreyOn: 'calendar-ok-grey',
    closedOn: 'calendar-crossed-grey',
    remaining: 'weeks-remaining',
    'remaining-banner': 'weeks-remaining',
    reward: 'gift-letter',
    customTag: 'custom-tag'
  };
}
