import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import {
  filterPopupAndConclusion,
  filterRealValues,
  isNonEmptyArray,
  isRealValue,
  mockParticipantId,
  ModuleSettings,
  ModuleStage,
  ProcedureTemplateType,
} from '@tr-common';

import { IProcedure, MinimalStudyType, Procedures, ProcedureStatusType } from '../../../models';
import {
  ActiveQuestionCollection,
  AnsweredQuestion,
  calcParentQuestionState,
  composeStatusOfIntroOrResearch,
  disabledQuestionsWithoutAnswer,
  DraftAnswersType,
  ParamsIDs,
  ResearchProcedureWithUserIDType,
  SurveyIntroOrResearchPageType,
  SurveyPreselect,
  UserStudyType,
} from '../../models';
import { SurveyService } from '../../services/survey.service';
import {
  changeActiveProcedure,
  changeActiveQuestion,
  checkIfStudyAlreadyStarted,
  clickResearchProcedure,
  donateAq,
  emailStudyTurnOff,
  finishProcedure,
  flowPreviewAnswers,
  getUserStudy,
  introContinue,
  loadIntroPage,
  loadModuleSettings,
  loadStudyFull,
  NavigateActions,
  navigateToQuestion,
  nextProcedure,
  nextQuestion,
  preselectQuestion,
  previousQuestion,
  refreshStudyQuestions,
  resetAnswersSaveError,
  returnToDashboard,
  saveAnswers,
  selectActiveAnswerSaveError,
  selectActiveProcedure,
  selectActiveProcedureParentFilteredQuestionsByRules,
  selectActiveQuestionID,
  selectBlockedNavigation,
  selectFullStudyLoading,
  selectIntro,
  selectModuleSettings,
  selectParamsIDs,
  selectParentQuestionState,
  selectPending,
  selectPreviewMode,
  selectProcedures,
  selectRefreshPending,
  selectScreeningProcedure,
  selectSourceType,
  selectStudy,
  selectUserProcedures,
  selectUserStudy,
  setActiveSurvey,
  startSurvey,
  studyResetState,
  submitProcedure,
  SurveyState,
} from '../index';

@Injectable({providedIn: 'root'})
export class StudyFacade {
  study$: Observable<MinimalStudyType> = this.store.select(selectStudy).pipe(filterRealValues());
  activeQuestionID$: Observable<string> = this.store.select(selectActiveQuestionID).pipe(filterRealValues());
  procedures$: Observable<Procedures> = this.store.select(selectProcedures).pipe(filter(isNonEmptyArray));
  procedureQuestions$: Observable<AnsweredQuestion[]> = this.store.select(selectActiveProcedureParentFilteredQuestionsByRules).pipe(
    filter(isNonEmptyArray), map(disabledQuestionsWithoutAnswer)
  );
  userStudy$: Observable<UserStudyType> = this.store.select(selectUserStudy).pipe(filterRealValues());
  activeProcedure$: Observable<IProcedure> = combineLatest([
    this.store.select(selectActiveProcedure).pipe(filter(isRealValue)),
    this.store.select(selectRefreshPending)
  ]).pipe(
    map(([procedure, isRefreshing]) => isRefreshing ? null : procedure)
  );
  procedureStatus$: Observable<ProcedureStatusType> = combineLatest([
    this.store.select(selectModuleSettings),
    this.store.select(selectScreeningProcedure).pipe(filter(isRealValue)),
    this.store.select(selectParamsIDs),
    this.store.select(selectSourceType),
    this.store.select(selectActiveProcedure).pipe(filter(isRealValue))
  ]).pipe(
    map(([settings, screening, {userID, userStudyID}, source, activeProcedure]) => ({
      ...screening,
      source,
      activeProcedure,
      userID, userStudyID, isResearch: false, isQuestionnaire: false, hasQuestions: true, nextProcedureTitle: null,
      setting: settings.screening?.find(s => s.module_type === 'page' && s.stage === ModuleStage.screening_failed),
    } as const))
  );
  popupSettings$: Observable<ModuleSettings[]> = this.activeProcedure$.pipe(
    filterRealValues(),
    withLatestFrom(this.store.select(selectModuleSettings)),
    map(([{id}, moduleSettings]) => isNonEmptyArray(moduleSettings[id]) ? moduleSettings[id].filter(filterPopupAndConclusion) : [])
  );
  fullStudyLoading$ = this.store.select<boolean>(selectFullStudyLoading);
  pending$ = this.store.select<boolean>(selectPending);
  researchProcedure$: Observable<ResearchProcedureWithUserIDType> = this.store.select(selectParamsIDs).pipe(
    switchMap(({userID, userStudyID}) => this.surveyService.loadResearchBody(userID, userStudyID).pipe(
      map(([researchProcedure]) => ({...researchProcedure, userID}))
    )),
  );
  researchPage$: Observable<SurveyIntroOrResearchPageType> = this.store.select(selectParamsIDs).pipe(
    // FixMe The solution below was proposed because the admin part couldn't load StudyPreviewSurveyService. Look at TR-4426.
    switchMap(({userID, userStudyID, studyID}) => {
      const mockStudyId = userID === mockParticipantId ? studyID : undefined;

      return this.surveyService.loadResearchBody(userID, userStudyID, mockStudyId).pipe(
        withLatestFrom(
          this.store.select(selectStudy),
          this.store.select(selectUserStudy),
          this.store.select(selectActiveProcedure),
          this.store.select(selectUserProcedures)
        ),
        map(([
          [{text, title, button_text, url, add_participant_id_query_param, id}],
          study, userStudy, procedure, procedures
        ]) => ({
          ...composeStatusOfIntroOrResearch(text, study, userStudy, procedure, procedures),
          isResearchOpportunity: true,
          id, userID, url, add_participant_id_query_param, title, button: button_text ?? 'Start now'
        }))
      );
    })
  );
  introPage$: Observable<SurveyIntroOrResearchPageType> = this.store.select(selectIntro).pipe(
    filterRealValues(),
    withLatestFrom(
      this.store.select(selectStudy),
      this.store.select(selectUserStudy),
      this.store.select(selectActiveProcedure),
      this.store.select(selectUserProcedures),
      this.store.select(selectParamsIDs),
    ),
    map(([{content}, study, userStudy, procedure, procedures, {userID, userStudyID}]) => ({
      ...composeStatusOfIntroOrResearch(content, study, userStudy, procedure, procedures),
      userID, isResearchOpportunity: false,
    }))
  );
  activeQuestion$: Observable<ActiveQuestionCollection> = combineLatest([
    this.store.select(selectParentQuestionState),
    this.store.select(selectPending),
    this.store.select<boolean>(selectPreviewMode),
  ]).pipe(
    map(([parentQuestionState, isPending, previewMode]) => calcParentQuestionState(parentQuestionState, isPending, previewMode))
  );
  showHint$ = new BehaviorSubject<boolean>(undefined);
  answerSaveError$: Observable<string[]> = this.store.select(selectActiveAnswerSaveError);
  blockedNavigation$: Observable<boolean> = this.store.select(selectBlockedNavigation);

  constructor(
    private surveyService: SurveyService,
    private store: Store<SurveyState>
  ) {}

  setParamsIDsFromScratch(payload: Partial<ParamsIDs>): void {
    this.store.dispatch(setActiveSurvey({payload}));
  }

  loadStudyFull({userID, studyID, userStudyID}: ParamsIDs): void {
    this.store.dispatch(loadStudyFull({userID, studyID, userStudyID, sourceType: 'scratch'}));
  }

  setActiveProcedure(payload: string): void {
    this.store.dispatch(changeActiveProcedure({payload}));
  }

  setActiveQuestion(payload: string): void {
    this.store.dispatch(changeActiveQuestion({payload}));
  }

  cleanAllIDs(): void {
    this.store.dispatch(setActiveSurvey(null));
    this.store.dispatch(changeActiveProcedure(null));
    this.store.dispatch(changeActiveQuestion(null));
  }

  checkIfStudyAlreadyStarted(): void {
    this.store.dispatch(checkIfStudyAlreadyStarted());
  }

  getUserStudy(): void {
    this.store.dispatch(getUserStudy());
  }

  loadModuleSettings(studyId: string, procedure: ProcedureTemplateType, showModal = false): void {
    this.store.dispatch(loadModuleSettings({studyId, procedure, showModal}));
  }

  loadIntroPage(): void {
    this.store.dispatch(loadIntroPage({checkAndRedirectToStatus: false}));
  }

  startSurveyFromRoot(): void {
    this.store.dispatch(startSurvey());
  }

  returnToDashboard(preview = false): void {
    if (preview) {
      this.finishProcedure(preview);
    } else {
      this.store.dispatch(returnToDashboard());
    }
  }

  saveAnswers(draftAnswers: DraftAnswersType, navigate: NavigateActions, preview: boolean): void {
    this.store.dispatch(saveAnswers({draftAnswers, navigate, preview}));
  }

  resetAnswersSaveError(): void {
    this.store.dispatch(resetAnswersSaveError());
  }

  nextQuestion(isLastQuestion: boolean, preview: boolean): void {
    if (isLastQuestion) {
      this.finishProcedure(preview);
    } else {
      this.store.dispatch(nextQuestion({preview}));
    }
  }

  previousQuestion(preview: boolean): void {
    this.store.dispatch(previousQuestion({preview}));
  }

  nextProcedure(): void {
    this.store.dispatch(nextProcedure());
  }

  continueAfterIntro(): void {
    this.store.dispatch(introContinue());
  }

  preselectQuestion(): void {
    this.store.dispatch(preselectQuestion({payload: SurveyPreselect.regular}));
  }

  navigateToQuestion(questionId: string): void {
    this.store.dispatch(navigateToQuestion({questionId}));
  }

  submitProcedure({id, userID}: ProcedureStatusType | SurveyIntroOrResearchPageType, hasConclusionPopup: boolean): void {
    this.store.dispatch(refreshStudyQuestions());
    this.store.dispatch(submitProcedure({id, userID, hasConclusionPopup}));
  }

  flowPreviewAnswers(): void {
    this.store.dispatch(flowPreviewAnswers());
  }

  clickResearchProcedure(promotionID: string): void {
    this.store.dispatch(clickResearchProcedure({promotionID}));
  }

  finishProcedure(preview: boolean): void {
    const message = 'To complete the questionnaire, please answer all of the required questions.';

    this.store.dispatch(finishProcedure({preview, message}));
  }

  studyResetState(): void {
    this.store.dispatch(studyResetState());
  }

  aqDonate(studyId: string): void {
    this.store.dispatch(donateAq({studyId}));
  }

  emailTurnOff(participant_id:string,study_id:string,email_notification_toggle:boolean): void {
  this.store.dispatch(emailStudyTurnOff({participant_id,study_id,email_notification_toggle}))
  }
}
