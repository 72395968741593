import { Action, createReducer, on } from '@ngrx/store';
import { dataInsights, dataInsightsLoadLoadFail, dataInsightsLoadSuccess } from '../store/studies.actions';


export interface StudiesState {
  dataInsights: {
    age_of_diagnosis: {
      diagnosis_data: number[];
      diagnosis_text: string;
    };
    cgm_usage: {
      cgm_data: number[];
      cgm_text: string;
    };
    autoimmune_diseases: {
      autoimmune_diseases_data: number[];
      ad_text: string;
    };
    family_history: {
      family_data: number[];
      family_member_data: number[];
      family_text: string;
    };
    glp_1_usage: {
      glp_1_usage_data: number[];
      glp_text: string;
    };
    duration_of_diabetes: {
      duration: number[];
      duration_text: string;
    };
  } | null;
  error: string | null;
  pending: boolean;
}

// Define the initial state
export const studiesInitialState: StudiesState = {
  dataInsights: null,
  error: null,
  pending: false
};


const reducer = createReducer(
  studiesInitialState,
 
  on(dataInsights, state => ({
    ...state,
    error: null,
    pending: true
  })),

  on(dataInsightsLoadSuccess, (state, { res }) => {
    return {
      ...state,
      dataInsights: res,
      error: null,
      pending: false
    };
  }),
  
  
  on(dataInsightsLoadLoadFail, (state, { error }) => ({
    ...state,
    error: error.toString(),
    pending: false
  }))
);


export function studiesReducer(state: StudiesState | undefined, action: Action): StudiesState {
  return reducer(state, action);
}
