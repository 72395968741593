import { ValueOf } from 'tr-lib';

export const sourcesToComponent = {
  dashboard: 'Dashboard',
  dualFactor: 'DualFactor',
  assentReconsent: 'assent-or-reconsent',
  default: 'Default',
  fromRegister: 'FromRegister',
  fromDataInsights: 'dataInsights',
};
export type SourcesToComponentType = ValueOf<typeof sourcesToComponent>;
