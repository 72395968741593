<ng-container *ngIf="reviewAnswersStatus$ | async as status;  else isLoading">
  <div class="wrapper">
    <div class="flex align-center review-actions">
      <lib-goto-back-link (redirectTo)="location.back()"></lib-goto-back-link>
      <span class="empty-space"></span>
      <!-- Gadgets print only first page -->
      <button *ngIf="!layout.isGadget" mat-flat-button class="survey-button is-with-icon" color="secondary" (click)="printPDF()">
        <mat-icon svgIcon="print"></mat-icon><span>Print</span>
      </button>
      <button mat-flat-button class="survey-button is-with-icon" color="secondary" (click)="downloadPDF()">
        <mat-icon svgIcon="download"></mat-icon><span>Download</span>
      </button>
    </div>

    <div class="tr-review-answers-list">
      <iframe #iframeContainer [srcdoc]="status.content | safeHtml" width="100%" (load)="onParsedHtml()"></iframe>
    </div>
    <div class="date-stamp">{{ status.dateTime }}</div>
  </div>
</ng-container>
<ng-template #isLoading>
  <lib-loader></lib-loader>
</ng-template>