import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  ConsentService,
  ContactInfoModule,
  GlobalRef,
  GotoBackLinkModule,
  MaterialImportsModule,
  REGISTRY_ADMIN_URL,
  REGISTRY_API_URL,
  REGISTRY_APPLICATION,
  REGISTRY_LOCAL_URL,
  REGISTRY_PARTICIPANT_URL,
  REGISTRY_PUBLIC_PARTICIPANT,
  REGISTRY_PUBLIC_URL,
  REGISTRY_STUDY_ANSWER,
  REGISTRY_USER_FILES,
  STORAGE,
  SurveyService,
  SurveyUrlFactory,
  SurveyUrlFactoryProxy,
  TRACKING,
  TrLibModule,
} from 'tr-lib';

import { ReConsentAuthGuard } from '@app/auth-guards/re-consent-auth-guard.service';
import { LoaderComponent } from '@app/components/loader/loader.component';
import { RegisterSuccessComponent } from '@app/components/register-success/register-success.component';
import { ConfirmationDialogRegistryComponent } from '@app/dialogs/confirm-dialog/confirm-dialog-registry.component';
import { ErrorDialogComponent } from '@app/dialogs/error-dialog/error-dialog.component';
import { JaebIntroDialogComponent } from '@app/dialogs/jaeb-intro-dialog/jaeb-intro-dialog.component';
import { MissedStudiesDialogModule } from '@app/dialogs/missed-studies-dialog/missed-studies-dialog.module';
import {
  ALCHEMER_DEXCOM,
  ALCHEMER_DEXCOM_MODE,
  alchemerDexcomClientIdentifierFactory,
  alchemerDexcomModeFactory,
  consentServiceFactory,
  DEXCOM,
  DEXCOM_API_PROD,
  DEXCOM_API_TEST,
  DEXCOM_BUTTON_ON,
  dexcomButtonOnFactory,
  dexcomClientIdentifierFactory,
  surveyServiceFactory,
  surveyUrlFactoryProxyFactory,
  T1D_CLIENT_ID,
  T1D_OAUTH_URL,
  t1dClientIdFactory,
  t1dOAuthUrlFactory,
} from '@app/model';
import { DexcomGuard } from '@app/dexcom/services/dexcom.guard';
import { httpInterceptorProviders } from '@app/shared/interceptors/http.interceptors';
import { SharedModule } from '@app/shared/shared.module';
import { CountryCodesService } from '@services/country-codes.service';
import { AuthService } from '@services/auth.service';
import { RegistryErrorHandler } from '@services/registry.error-handler';
import { environment } from '@env/environment';
import { RegistryStoreModule } from '@store/registry-store.module';
import { DexcomWrapperComponent } from '@dexcom/pages/dexcom-wrapper/dexcom-wrapper.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlreadyLoggedInGuard } from './auth-guards/already-logged-in.guard';
import { AuthGuard } from './auth-guards/auth.guard';
import { MissedProfileTypeDialogComponent } from './dialogs/missed-profile-type-dialog/missed-profile-type-dialog.component';
import { PersonalizePipe } from './shared/pipes/personalize.pipe';
import { ContentWrapperWithBackButtonComponent } from './components/content-wrapper/content-wrapper-with-back-button.component';
import { CircularLoaderComponent } from './components/circular-loader/circular-loader.component';

@NgModule({
  declarations: [
    AppComponent,
    ContentWrapperWithBackButtonComponent,
    RegisterSuccessComponent,
    PersonalizePipe,
    ErrorDialogComponent,
    JaebIntroDialogComponent,
    ConfirmationDialogRegistryComponent,
    MissedProfileTypeDialogComponent,
    LoaderComponent,
    DexcomWrapperComponent,
    CircularLoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    RegistryStoreModule,
    GotoBackLinkModule,
    ContactInfoModule,
    TrLibModule,
    MaterialImportsModule,
    SharedModule.forBrowser(),
    MissedStudiesDialogModule,
  ],
  providers: [
    AuthGuard,
    AlreadyLoggedInGuard,
    ReConsentAuthGuard,
    DexcomGuard,
    {provide: STORAGE, useFactory: () => isDevMode() ? localStorage : sessionStorage},
    {provide: REGISTRY_API_URL, useValue: environment.apiUrl},
    {provide: REGISTRY_APPLICATION, useValue: 'Public'},
    {provide: REGISTRY_ADMIN_URL, useValue: environment.apiAdminUrl},
    {provide: REGISTRY_PUBLIC_URL, useValue: environment.apiPublicUrl},
    {provide: REGISTRY_PUBLIC_PARTICIPANT, useValue: environment.registerUrl},
    {provide: REGISTRY_USER_FILES, useValue: environment.getUserFiles},
    {provide: REGISTRY_STUDY_ANSWER, useValue: environment.studyAnswersUrl},
    {provide: REGISTRY_LOCAL_URL, useValue: environment.registry},
    {provide: ConsentService, useFactory: consentServiceFactory, deps: [Injector, HttpClient]},
    {provide: REGISTRY_PARTICIPANT_URL, useValue: environment.apiParticipantUrl},
    {provide: SurveyService, useFactory: surveyServiceFactory, deps: [Injector, HttpClient]},
    ...httpInterceptorProviders,
    {provide: TRACKING, useValue: true},
    AuthService,
    CountryCodesService,
    SurveyUrlFactory,
    {provide: SurveyUrlFactoryProxy, useFactory: surveyUrlFactoryProxyFactory, deps: [Injector]},
    {provide: DEXCOM, useFactory: dexcomClientIdentifierFactory, deps: [GlobalRef]},
    {provide: DEXCOM_BUTTON_ON, useFactory: dexcomButtonOnFactory, deps: [GlobalRef]},
    {provide: ALCHEMER_DEXCOM, useFactory: alchemerDexcomClientIdentifierFactory, deps: [GlobalRef]},
    {provide: ALCHEMER_DEXCOM_MODE, useFactory: alchemerDexcomModeFactory, deps: [GlobalRef]},
    {provide: DEXCOM_API_TEST, useValue: environment.dexcom.test},
    {provide: DEXCOM_API_PROD, useValue: environment.dexcom.prod},
    {provide: ErrorHandler, useClass: RegistryErrorHandler},
    {provide: T1D_CLIENT_ID, useFactory: t1dClientIdFactory, deps: [GlobalRef]},
    {provide: T1D_OAUTH_URL, useFactory: t1dOAuthUrlFactory, deps: [GlobalRef]},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
