<ng-container *ngIf="closedStudyStatus$ | async as status; else isLoading">
  <div class="flex wrapper">
    <div class="flex shortcuts">
      <lib-goto-back-link *ngIf="urls.proxyFactory.getDashboardUrl$() | async as url" buttonTitle="Back to Dashboard"
        (redirectTo)="router.navigateByUrl(url)">
      </lib-goto-back-link>
      <lib-contact-info [flatBtn]="true"></lib-contact-info>
    </div>

    <ng-container *ngIf="status.isResearchStudy; else standardClosedStatus">
      <lib-procedure-research-status [showBanner]="true"></lib-procedure-research-status>
    </ng-container>
  </div>

  <ng-template #standardClosedStatus>
    <lib-study-is-closed-banner class="flex gap-10" directionType="right"></lib-study-is-closed-banner>

    <div class="procedure-closed-status">
      <div class="flex intro-title mbs-20">
        <mat-icon svgIcon="research"></mat-icon><h3 class="procedure-status-title">{{ status.study.title }}</h3>
      </div>

      <hr class="intro-title mbs-10 mbe-10"/>

      <h4 class="intro-title mbs-30 mbe-40">Study is expired</h4>
    </div>

    <lib-study-is-closed-banner class="flex gap-10" directionType="left"></lib-study-is-closed-banner>
  </ng-template>
</ng-container>

<ng-template #isLoading>
  <lib-loader></lib-loader>
</ng-template>
