import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';

import {
  AppComponentStorageType,
  fillStorage,
  IconsRegisterService,
  LayoutService,
  libSvgIcons,
  SessionStorage,
  STORAGE,
  TrackingService,
} from 'tr-lib';

import { selectGlobalLoader } from '@store/selectors';
import { allSvgIcons, AppState } from '@app/model';

@Component({
  selector: 'app-root',
  template: `
    <app-circular-loader *ngIf="globalLoader$ | async"></app-circular-loader>
    <router-outlet></router-outlet>
  `})
export class AppComponent implements OnInit, OnDestroy {
  globalLoader$ = this.store.select(selectGlobalLoader).pipe(delay(0));
  url: string;
  private subscriptions = new Subscription();

  constructor(
    @Inject(STORAGE) private storage: SessionStorage<AppComponentStorageType>,
    public router: Router,
    private ts: TrackingService,
    private layout: LayoutService,
    private icons: IconsRegisterService,
    private store: Store<AppState>,
  ) {
    fillStorage(storage);
    this.icons.initSvgIcons([...libSvgIcons, ...allSvgIcons]);
  }

  @HostListener('body:scroll', ['$event']) onHostScroll = ({srcElement, target}) => {
    this.updateLayoutService(srcElement, target['scrollTop']);
  }

  @HostListener('window:scroll', ['$event']) onScroll = ({srcElement, target: {documentElement}}) => {
    this.updateLayoutService(srcElement['documentElement'], documentElement['scrollTop']);
  };

  ngOnInit(): void {
    this.subscriptions.add(this.router.events.pipe(tap(event => {
      if (event instanceof NavigationStart) {
        this.url = event.url;
      } else if (event instanceof NavigationEnd && event.id !== 1) {
        this.ts.trackPage(event.url)
      }
    })).subscribe());
    this.subscriptions.add(this.layout.isMobileUsingLandscape$.pipe(
      filter((isLandscape: boolean) => isLandscape)
    ).subscribe(() => this.ts.trackRotate()));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateLayoutService(scrollSrcElement: HTMLElement, scrollTop: number) {
    this.layout.scrollSrcElement = scrollSrcElement;
    this.layout.scrollTop$.next(scrollTop);
  }
}
