import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { groupBy } from 'lodash';
import { concatMap, forkJoin, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
  failSnackBottomConstantConfig,
  GroupedSettingsByProcedure,
  hasProcedureWelcomeDialog,
  isNullOrUndefined,
  isRealValue,
  ProcedureTemplateType,
  ProcedureTemplateTypes,
  somethingWentWrong,
  successSnackConfig,
} from '@tr-common';

import { IProcedure, isIntroOrPromotionalProcedure, isSpecialProcedure, procedureHasQuestions } from '../../../models';
import { EventsLogService } from '../../../services';
import {
  AllIDs,
  FullStudy,
  getMinimalStudyInfoFromUserStudy,
  getReviewOrFullPreselect,
  isCoreStudy,
  isMockUserStudy,
  SurveyPreselect,
} from '../../models';
import { checkIsIntroOrConsentCompletedThenNext } from '../../models/survey-check';
import { SurveyRouter } from '../../services/survey-router.service';
import { SurveyIsolatedActions } from '../../services/survey-url-factory.service';
import { SurveyService } from '../../services/survey.service';
import {
  changeActiveProcedure,
  checkIfStudyAlreadyStarted,
  checkPreselect,
  checkShowingWelcomeDialog,
  checkStudyEligibility,
  donateAq,
  donateAqFailure,
  donateAqSuccess,
  emailStudyTurnOff,
  emailStudyTurnOffFail,
  finishPending,
  flowReviewAnswers,
  getUserStudy,
  getUserStudyFail,
  getUserStudySuccess,
  gotoProcedure,
  loadAnswersFromAnotherStudy,
  loadAnswersSuccess,
  loadIntroPage,
  loadModuleSettings,
  loadModuleSettingsFail,
  loadModuleSettingsSuccess,
  loadParticipantDetailsFail,
  loadParticipantDetailsSuccess,
  loadStudyFull,
  loadStudyFullFail,
  loadStudyFullIfNotEligibleSuccess,
  loadStudyFullStage2,
  loadStudyFullSuccess,
  markProceduresEnabled,
  nextProcedure,
  preselectProcedure,
  preselectQuestion,
  refreshHeaderWithProcedures,
  registerStartedStudy,
  registerStartedStudyFail,
  registerStartedStudySuccess,
  restartSurvey,
  returnToDashboard,
  routeToNotEligiblePage,
  selectActiveProcedure,
  selectAllQueryParameters,
  selectAnswers,
  selectCanSubmitActiveProcedure,
  selectConsents,
  selectNextActiveProcedure,
  selectParamsIDs,
  selectParticipantDetails,
  selectProcedures,
  selectStudy,
  selectUrlProcedure,
  selectUserProcedures,
  startSurvey,
  studyResetState,
} from '../index';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class SurveyEffects {
  actions$ = new Subject<Action>();

  checkIfStudyAlreadyStarted$ = createEffect(() => this.actions$.pipe(
    ofType(checkIfStudyAlreadyStarted),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([, {studyID, userStudyID, userID}]) => this.events.isStudyAlreadyStarted(userID, studyID, +userStudyID).pipe(
      filter(isStarted => !isStarted),
      map(() => registerStartedStudy({studyID, userStudyID, userID})),
      catchError(({error}) => of(registerStartedStudyFail({error})))
    ))
  ));

  registerStartedStudy$ = createEffect(() => this.actions$.pipe(
    ofType(registerStartedStudy),
    switchMap(({studyID, userStudyID, userID}) => this.events.registerStartedStudy(userID, studyID, +userStudyID).pipe(
      map(payload => registerStartedStudySuccess({payload})),
      catchError(({error}) => of(registerStartedStudyFail({error})))
    ))
  ));

  registerStartedStudyFail$ = createEffect(() => this.actions$.pipe(
    ofType(registerStartedStudyFail),
    tap(() => this.snackBar.open(somethingWentWrong, 'X', failSnackBottomConstantConfig())),
  ), {dispatch: false});

  getUserStudy$ = createEffect(() => this.actions$.pipe(
    ofType(getUserStudy),
    withLatestFrom(this.store.select(selectParamsIDs)),
    switchMap(([, {userID, userStudyID, studyID}]) => this.surveyService.getUserStudy(userID, userStudyID).pipe(
      map(payload => getUserStudySuccess({payload})),
      catchError(({error}) => of(getUserStudyFail({error})))
    ))
  ));

  reviewAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(flowReviewAnswers),
    withLatestFrom(this.store.select(selectParamsIDs)),
    tap((
      [{procedure}, {studyID, userStudyID, userID}]
    ) => this.surveyRouter.navigateToAnswersReview({studyID, userStudyID, userID, procedure})),
  ), {dispatch: false});

  gotoProcedure$ = createEffect(() => this.actions$.pipe(
    ofType(gotoProcedure),
    withLatestFrom(
      this.store.select(selectParamsIDs),
      this.store.select(selectUserProcedures),
    ),
    map(([{payload, isCompleted}, {studyID, userStudyID, userID}, userProcedures]) => {
      if (isNullOrUndefined(userStudyID) || isNullOrUndefined(studyID)) {
        const foundUserProcedure = userProcedures.find(uc => uc.procedure_id === ProcedureTemplateTypes.consent);
        // Only for cases when a participant tries to change procedure when review its consent
        userStudyID = foundUserProcedure.user_study_id.toString();
        studyID = foundUserProcedure.study_id;
      }

      return {payload, isCompleted, studyID, userStudyID, userID, userProcedures};
    }),
    tap(({payload, isCompleted, studyID, userStudyID, userID, userProcedures}) => {
      const studyData = {studyID, userStudyID, userID, procedureId: payload, questionId: null};

      // console.log({handler: 'gotoProcedure$', isCompleted, ...studyData});
      this.surveyRouter.navigateToProcedure(studyData, isCompleted).then(ok => {
        if (ok) {
          this.store.dispatch(changeActiveProcedure({payload}));
        }
      });
    })
  ), {dispatch: false});

  nextProcedure$ = createEffect(() => this.actions$.pipe(
    ofType(nextProcedure),
    withLatestFrom(this.store.select(selectNextActiveProcedure), this.store.select(selectParamsIDs)),
    filter(([, toProcedure]) => isRealValue(toProcedure)),
    tap(([, toProcedure, {userStudyID, userID, studyID}]: [typeof nextProcedure, IProcedure, AllIDs]) => {
      const ids = {userStudyID, procedureId: toProcedure.id, userID, studyID}; // renamed props to pass to surveyRouter

      this.store.dispatch(changeActiveProcedure({payload: toProcedure.id}));
      // I commented it because after using the button Back, a participant could not navigate from intro to screening
      // this.store.dispatch(changeActiveQuestion(null));
      this.surveyRouter.navigateToProcedure(ids, isRealValue(toProcedure.created_at)).then(() => this.store.dispatch(finishPending()));
    }),
  ), {dispatch: false});

  markProceduresEnabled$ = createEffect(() => this.actions$.pipe(
    ofType(loadStudyFullSuccess, loadAnswersSuccess, refreshHeaderWithProcedures),
    withLatestFrom(
      this.store.select(selectProcedures),
      this.store.select(selectAnswers),
    ),
    map(([, procedures, answers]) => markProceduresEnabled({payload: {procedures, answers}})),
  ));

  /**
   * no procedureId preselects based on procedure statuses,
   * no questionId preselects a first unanswered question
   * if all questions are answered then it redirects to procedure status page
   */
  checkPreselect$ = createEffect(() => this.actions$.pipe(
    ofType(checkPreselect),
    withLatestFrom(
      this.store.select(selectParamsIDs),
      this.store.select(selectAllQueryParameters),
      this.store.select(selectCanSubmitActiveProcedure),
      this.store.select(selectActiveProcedure),
      this.store.select(selectParticipantDetails),
      this.store.select(selectConsents),
      this.store.select(selectStudy)
    ),
    tap(([
      {payload}, {userID, studyID, userStudyID}, {procedure, questionId, preview}, submitReady, activeProcedure, participant, consents, study
    ]) => {
      // console.log({handler: 'checkPreselect$', submitReady, userID, studyID, userStudyID, procedure, questionId});
      if (isRealValue(procedure)) {
        const hasQuestions = procedureHasQuestions(procedure as ProcedureTemplateType);

        if (payload === SurveyPreselect.review) {
          this.store.dispatch(flowReviewAnswers({procedure}));
        } else if (isRealValue(activeProcedure) && isSpecialProcedure(procedure as ProcedureTemplateType)) {
          checkIsIntroOrConsentCompletedThenNext(payload, activeProcedure, consents, participant, this.store);
        } else if (submitReady && isNullOrUndefined(preview) && payload !== SurveyPreselect.status) {
          this.surveyRouter.navigateToProcedureStatus({
            studyId: studyID, profileId: userID, userStudyId: userStudyID, queryParams: {procedure},
          }, true).then(() => this.store.dispatch(finishPending()));
        } else if (isNullOrUndefined(questionId) && payload !== SurveyPreselect.status && hasQuestions) {
          this.store.dispatch(preselectQuestion({payload}));
        }
      } else {
        this.store.dispatch(preselectProcedure({study}));
      }
    }),
  ), {dispatch: false});



  loadModuleSettings$ = createEffect(() => this.actions$.pipe(
    ofType(loadModuleSettings),
    filter(({procedure}) => !isIntroOrPromotionalProcedure(procedure)),
    switchMap(({studyId, procedure, showModal}) => this.surveyService.loadModuleSettings(studyId).pipe(
      map(ms => loadModuleSettingsSuccess({
        procedure, showModal, moduleSettings: groupBy(ms, 'procedure') as GroupedSettingsByProcedure
      })),
      catchError(err => of(loadModuleSettingsFail({err}))),
    )),
  ));

  loadModuleSettingsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loadModuleSettingsSuccess),
    filter(({procedure, showModal}) => showModal && isRealValue(procedure) && hasProcedureWelcomeDialog(procedure)),
    map(({procedure}) => checkShowingWelcomeDialog({procedure}))
  ));

  loadStudyFull$ = createEffect(() => this.actions$.pipe(
    ofType(loadStudyFull),
    switchMap(({studyID, userID, userStudyID, sourceType}) => forkJoin([
      this.surveyService.getParticipantDetails(userID),
      this.surveyService.loadAnswers(userID, userStudyID),
    ]).pipe(
      concatMap(([participant, answers]) => [
        loadParticipantDetailsSuccess({payload: participant}),
        loadAnswersSuccess({payload: groupBy(answers, 'question')}),
        loadStudyFullStage2({studyID, userStudyID, userID, sourceType, participant})
      ]),
      catchError(({error}) => of(loadStudyFullFail({error})))
    )),
  ));

  loadStudyFullStage2$ = createEffect(() => this.actions$.pipe(
    ofType(loadStudyFullStage2),
    switchMap(({studyID, userID, userStudyID, participant, sourceType}) => forkJoin([
      this.surveyService.loadProcedures(studyID),
      this.surveyService.studyUserProcedures(userID, userStudyID),
      this.surveyService.loadQuestionsRules(studyID),
      this.surveyService.getUserStudies(userID),
      this.surveyService.getUserStudy(userID, userStudyID),
      this.surveyService.loadQuestions(participant.profile_type, studyID).pipe(map(a => groupBy(a, 'procedure'))),
    ]).pipe(
      map((
        [studyProcedures, userProcedures, rules, userStudies, userStudy, questions]
      ) => ({study: getMinimalStudyInfoFromUserStudy(userStudy), studyProcedures, userProcedures, rules, userStudies, userStudy, questions})),
      map(r => checkStudyEligibility({study: {...r, participantID: userID, studyID, userStudyID, sourceType}})),
      catchError(({error}) => of(loadStudyFullFail({error}))),
    ))
  ));

  checkStudyEligibility$ = createEffect(() => this.actions$.pipe(
    ofType(checkStudyEligibility),
    withLatestFrom(this.store.select(selectUrlProcedure)),
    map(([{study}, procedure]) => {
      const isEligibleOrReview = study.userStudy.is_eligible !== false || study.sourceType === 'review';

      // console.log({handler: 'checkStudyEligibility$', isEligibleOrReview, study, procedure});
      if (study.sourceType === 'signJAEB') {
        this.store.dispatch(nextProcedure());
      } else if (!isEligibleOrReview) {
        const isIntro = isRealValue(procedure) && procedure === ProcedureTemplateTypes.intro;
        const payload = isIntro ? ProcedureTemplateTypes.intro : ProcedureTemplateTypes.screening;

        this.store.dispatch(changeActiveProcedure({payload}));
        if (isIntro) {
          this.store.dispatch(loadIntroPage({checkAndRedirectToStatus: false}));
        }
        if (study.sourceType !== 'notEligible') {
          this.store.dispatch(routeToNotEligiblePage({isCoreStudy: study.study.study_type === 'core_study'}));
        }
      }

      return isEligibleOrReview ? loadStudyFullSuccess(study) : loadStudyFullIfNotEligibleSuccess(study);
    }),
  ));

  checkStudyEligibilityNotEligible$ = createEffect(() => this.actions$.pipe(
    ofType(routeToNotEligiblePage),
    withLatestFrom(
      this.store.select(selectParamsIDs),
      this.store.select(selectParticipantDetails),
    ),
    tap(([, {userID, userStudyID, studyID}, {source}]) => {
      void this.surveyRouter.navigateToNotEligiblePage({userStudyID, userID, studyID, source});
    }),
  ), {dispatch: false});

  loadStudyFullSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(loadStudyFullSuccess),
    map((fullInformation: FullStudy) => {
      const isCoreOrMock = isCoreStudy(fullInformation) || isMockUserStudy(fullInformation);
      // FIXME it is an incorrect solution. We should not replace sourceType for the action checkPreselect()
      const payload = getReviewOrFullPreselect(fullInformation.sourceType);

      return isCoreOrMock ? checkPreselect({payload}) : loadAnswersFromAnotherStudy(fullInformation);
    }),
  ));

  /**
   * Exit from survey
   */
  returnToDashboard$ = createEffect(() => this.actions$.pipe(
    ofType(returnToDashboard),
    tap(() => this.surveyRouter.navigateToDashboard().then())
  ), {dispatch: false});

  /**
   * Start study from the scratch
   */
  startSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(startSurvey),
    withLatestFrom(this.store.select(selectParamsIDs)),
    tap(([, params]) => this.surveyRouter.navigateToRoot(params).then())
  ), {dispatch: false});
  /**
   * Restart study
   */
  restartSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(restartSurvey),
    tap(({studyID, userStudyID, userID}) => this.surveyRouter.navigateToStudyStart(studyID, userStudyID, userID)),
    map(() => studyResetState())
  ));

  somethingWentWrong$ = createEffect(() => this.actions$.pipe(
    ofType(loadParticipantDetailsFail, loadStudyFullFail),
    tap(() => this.snackBar.open(somethingWentWrong, null, failSnackBottomConstantConfig(5000))),
  ), {dispatch: false});

  
  // donateAq$ = createEffect(() => this.actions$.pipe(
  //   ofType(donateAq),
  //   switchMap(({ studyId }) => this.surveyService.donateAq(studyId).pipe(
  //     map(payload => donateAqSuccess({payload})),
  //     catchError(error => of(donateAqFailure({ error })))
  //   ))
  // ));
  donateAq$ = createEffect(() => this.actions$.pipe(
    ofType(donateAq),
    switchMap(({ studyId }) => this.surveyService.donateAq(studyId).pipe(
        map(payload => donateAqSuccess({ payload })),
        // Chain another action dispatch after successful donation
        concatMap((donateAqSuccessAction) => [
            donateAqSuccessAction, // Dispatch donateAqSuccess
            getUserStudy() // Dispatch getUserStudy
        ]),
        catchError(error => of(donateAqFailure({ error })))
    ))
));

  getFail$ = createEffect(() => this.actions$.pipe(
    ofType(donateAqFailure),
    tap(() => this.snackBar.open(somethingWentWrong, 'X', failSnackBottomConstantConfig())),
  ), {dispatch: false});

  emailStudyTurnOff$ = createEffect(() => this.actions$.pipe(
    ofType(emailStudyTurnOff),
    switchMap(({participant_id, study_id, email_notification_toggle}) => 
      this.surveyService.emailStudyToggle(participant_id, study_id, email_notification_toggle).pipe(
        switchMap((result: { email_notification_toggle: boolean }) => {
          result.email_notification_toggle === false 
          ? this.snackBar.open('Emails for this study turned off', null, successSnackConfig())
          :this.snackBar.open('Emails for this study turned on', null, successSnackConfig());
          return of(); 
        }),
        catchError(err => of(emailStudyTurnOffFail({ err })))
      )
    )
  ));
  
  emailStudyTurnOffFail$ = createEffect(() => this.actions$.pipe(
    ofType(emailStudyTurnOffFail),
    tap(() =>  this.snackBar.open(somethingWentWrong, 'X', failSnackBottomConstantConfig()))
  ), {dispatch: false});

  constructor(
    public surveyRouter: SurveyRouter,
    public store: Store<any>,
    public surveyService: SurveyService,
    public events: EventsLogService,
    private snackBar: MatSnackBar,
    private injector: Injector,
  ) {
    this.injector.get(SurveyIsolatedActions).actions$.subscribe(this.actions$);
  }
}
