import { createAction, props } from '@ngrx/store';



export enum StudiesActions {
    DATAINSIGHTS = '[DataInsights] Data Insights data',
    DATAINSIGHTS_SUCCESS = '[Participants] Data Insights data success',
    DATAINSIGHTS_FAIL = '[Participants] Data Insights data fail',
  }


  export const dataInsights = createAction(
    StudiesActions.DATAINSIGHTS,
    props<{req?:any}>()
  );
  
  export const dataInsightsLoadSuccess = createAction(
    StudiesActions.DATAINSIGHTS_SUCCESS,
    props<{res: any}>()
  );
  
  export const dataInsightsLoadLoadFail = createAction(
    StudiesActions.DATAINSIGHTS_FAIL,
    props<{error: unknown}>()
  );