import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScriptService } from '../../services';
import { isNonEmptyString, isRealValue } from '@tr-common';
import { environment } from '../../../environments/environment';



export const checkAndLoadRelevantIDScript = (imperiumClientID: string, scripts: ScriptService): Observable<boolean> => {
    const relevantID = environment.relevantID;
    let outcome = of(false);
    
    if (isNonEmptyString(imperiumClientID) && isRealValue(relevantID) && !scripts.isLoaded(relevantID.scriptName)) {
      const {scriptID, scriptName, scriptSrc} = environment.relevantID;
  
      scripts.register([{id: scriptID, name: scriptName, src: scriptSrc}]);
      outcome = scripts.loadScript(scriptName).pipe(map(() => true));
    }
  
    return outcome;
  };
  
  export const checkAndLoadCSOScript = (imperiumClientID: string, scripts: ScriptService): Observable<boolean> => {
    const imperiumCSO = environment.imperiumCSO;
    let outcome = of(false);
    const relevantIdScript = document.getElementById('relevantid');
    if (relevantIdScript) {
      relevantIdScript.remove();
    }
    if (isNonEmptyString(imperiumClientID) && isRealValue(imperiumCSO) && !scripts.isLoaded(imperiumCSO.scriptName)) {
      const {scriptID, scriptName, scriptSrc} = environment.imperiumCSO;
  
      scripts.register([{id: scriptID, name: scriptName, src: scriptSrc}]);
      outcome = scripts.loadScript(scriptName).pipe(map(() => true));
    }
  
    return outcome;
  };