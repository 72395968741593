import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Memoize } from 'lodash-decorators';

import { ModuleSettings, ProcedureTemplateTypes } from '@tr-common';
import { Observable } from 'rxjs';
import { SurveyState, selectParamsIDs, selectUserStudy } from '../../study-survey/store/index';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'lib-conclusion-page',
  template: `
    <div class="consent-status-info" *ngIf="setting">
      <div class="img"><img src="/assets/survey_status_2.svg" alt="survey-status"></div>

      <div class="text">
        <h5>{{ setting.content?.title }}</h5>
        <div [innerHTML]="setting.content?.body_text | safeHtml"></div>

        
        <div class="actions-content" *ngIf="setting?.content?.donation_button">
          <button class="survey-button is-shadowed donate-button" mat-flat-button color="primary" [spinner]="pending" [disabled]="pending || donateSuccess || isDonated"
            (click)="emitClickDonate()">Donate Gift Card
          </button>
          <p class="success-donation" *ngIf="donateSuccess">Success!</p>
        </div>
        <ng-content></ng-content>
        <div *ngIf="isEligible && (isScreening || isQuestionnaire)" class="actions-content">
          <button class="survey-button is-shadowed" mat-flat-button color="primary" [spinner]="pending" [disabled]="pending"
            (click)="emitClick()">{{ setting?.content?.button_text }}
          </button>
          <button *ngIf="isQuestionnaire && studyType === 'core_study'" class="survey-button is-shadowed data-insight-button" mat-flat-button color="primary" [spinner]="pending" [disabled]="pending"
          (click)="navigatetoDataInsights()" >Data Insights
        </button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./conclusion-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConclusionPageComponent {
  @Input() setting: ModuleSettings;
  @Input() pending: boolean;
  @Input() donateSuccess: boolean;
  @Input() isDonated: boolean;
  @Input() isEligible = true;
  @Output() clickNext = new EventEmitter();
  @Output() clickLast = new EventEmitter();
  @Output() clickDonate = new EventEmitter();
  getStudyParam$: Observable<any>;
  studyID: any;
  userStudyID:any
  userID:any
  returnUrl: string;
  private getStudyParamSub: Subscription;
  public studyType: string;
  @Memoize() get isScreening(): boolean {
    return this.setting.procedure === ProcedureTemplateTypes.screening;
  }

  @Memoize() get isQuestionnaire(): boolean {
    return this.setting.procedure === ProcedureTemplateTypes.questionnaire;
  }
  subscription = new Subscription();

  constructor(
    private store: Store<SurveyState>,
    public router: Router,

  ) {
    this.getStudyParam$ = this.store.select(selectParamsIDs);
    this.getStudyParamSub = this.getStudyParam$.subscribe(
      (params) => {
        if (params) {
          const { studyID, userStudyID, userID } = params;
          this.studyID = studyID;
          this.userStudyID = userStudyID;
          this.userID = userID;
        } else {
          console.log('No params emitted or undefined');
        }
      }
    );

    this.subscription.add(this.store.select(selectUserStudy).subscribe(userStudy => {
      this.studyType = userStudy.study_type
    }));
  }
  ngOnDestroy() {
    if (this.getStudyParamSub) {
      this.getStudyParamSub.unsubscribe();
    }
    this.subscription.unsubscribe();
  }

  emitClick(): void {
    if (this.isScreening) {
      this.clickNext.emit();
    } else if (this.isQuestionnaire) {
      this.clickLast.emit();
    }
  }

  emitClickDonate(): void {
    this.clickDonate.emit();
  }

  navigatetoDataInsights(){
    this.router.navigate([`/survey/study/${this.studyID}/user-study/${this.userStudyID}/user/${this.userID}/data-insights`])
  }
}
