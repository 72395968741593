import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';

import { ButtonSpinnerModule } from './button-spinner/button-spinner.module';
import { CommunicationModule } from './communication/communication.module';
import { BreadCrumbsComponent } from './components/bread-crumbs/bread-crumbs.component';
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { LogoutComponent } from './components/logout/logout.component';
import { StatusRendererComponent } from './components/status-renderer/status-renderer.component';
import { StudyIsClosedBannerComponent } from './components/study-is-closed-banner/study-is-closed-banner.component';
import { ContactInfoModule } from './contact-info/contact-info.module';
import { CountryFlagModule } from './country-flag/country-flag.module';
import { GotoBackLinkModule } from './goto-back-link/goto-back-link.module';
import { MaterialImportsModule } from './material-imports.module';
import { ModuleSettingsModule } from './module-settings/module-settings.module';
import { PhoneInputModule } from './phone-input/phone-input.module';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SurveyPipesModule } from './pipes/survey-pipes.module';
import { TextReplacePipe } from './pipes/text-replace.pipe';
import { IconsRegisterService, ScriptService } from './services';
import { SocietyInfoModule } from './society-info/society-info.module';
import { SignConsentFormComponent } from './study-survey/components/sign-consent-form/sign-consent-form.component';
import { SignJaebConsentComponent } from './study-survey/components/sign-jaeb-consent/sign-jaeb-consent.component';
import { WidgetsModule } from './widgets/widgets.module';
import { TagComponent } from './tag/tag.component';
import { GlobalRef } from './global/registry-global';
import { REG_GUARD_CLIENT_ID } from './global/tokens';
import { RegistryLib } from './models';
import { LoaderComponent } from './components/loader/loader.component'

export const regGuardClientFactory = (
  ref: GlobalRef<RegistryLib>
): string => ref.nativeGlobal.regGuardClientID;
@NgModule({
  declarations: [
    BreadCrumbsComponent,
    InfoTooltipComponent,
    LogoutComponent,
    StatusRendererComponent,
    SignConsentFormComponent,
    SignJaebConsentComponent,
    StudyIsClosedBannerComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonSpinnerModule,
    WidgetsModule,
    MaterialImportsModule,
    ContactInfoModule,
    CountryFlagModule,
    GotoBackLinkModule,
    TextMaskModule,
    ModuleSettingsModule,
    SocietyInfoModule,
    SurveyPipesModule,
    CommunicationModule,
    PhoneInputModule,
    TagComponent,
  ],
  exports: [
    CommunicationModule,
    ContactInfoModule,
    CountryFlagModule,
    GotoBackLinkModule,
    ButtonSpinnerModule,
    BreadCrumbsComponent,
    InfoTooltipComponent,
    LogoutComponent,
    StatusRendererComponent,
    SignConsentFormComponent,
    MaterialImportsModule,
    SignJaebConsentComponent,
    TextReplacePipe,
    SafeHtmlPipe,
    ModuleSettingsModule,
    SocietyInfoModule,
    SurveyPipesModule,
    StudyIsClosedBannerComponent,
    WidgetsModule,
    PhoneInputModule,
    TagComponent,
    LoaderComponent
  ],
  providers: [
    IconsRegisterService,
    ScriptService,
    {provide: REG_GUARD_CLIENT_ID, useFactory: regGuardClientFactory, deps: [GlobalRef]},
  ]
})
export class TrLibModule {}
