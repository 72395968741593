import * as t from 'io-ts';

export enum TimeIcons {
  approximate = 'approximate',
  bannerApproximate = 'approximate-banner',
  launchOn = 'launchOn',
  launchGreyOn = 'launchGreyOn',
  closedOn = 'closedOn',
  remaining = 'remaining',
  bannerRemaining = 'remaining-banner',
  reward = 'reward',
  customTag = 'customTag',
}
export const timeIconsSchema = t.union([
  t.literal(TimeIcons.approximate),
  t.literal(TimeIcons.bannerApproximate),
  t.literal(TimeIcons.launchOn),
  t.literal(TimeIcons.launchGreyOn),
  t.literal(TimeIcons.closedOn),
  t.literal(TimeIcons.remaining),
  t.literal(TimeIcons.bannerRemaining),
  t.literal(TimeIcons.reward),
  t.literal(TimeIcons.customTag),
], 'timeIconsSchema');
export type TimeIconType = t.TypeOf<typeof timeIconsSchema>;

export type TimeIconsType = {
  [key in TimeIcons]: string;
};
