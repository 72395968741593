import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';

import { isNonEmptyString, isRealValue, SessionStorage, STORAGE, storageKeys } from 'tr-lib';
import { cutTrEmailIdFromUrl, getTrEmailIdFromUrl } from '@app/model/tr-email-id';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  private token: string;

  constructor(
    private router: Router,
    private location: Location,
    @Inject(STORAGE) private storage: SessionStorage<typeof storageKeys.token>
  ) {}

  canActivate(snapshot: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivateOrLoad();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.canActivateOrLoad();
  }

  // canActivateOrLoad(): Observable<boolean> {
  //   const isTokenPresented = this.getTokenFromStorage();

  //   if (!isTokenPresented) {
  //     const sourceURL = this.location.path(true);
  //     const emailId = getTrEmailIdFromUrl(sourceURL);
  //     const trackEmailId = isRealValue(emailId) ? {'tr-email-id': emailId} : null;

  //     this.router.navigate(['/auth/login'], {
  //       queryParams: {redirectTo: btoa(cutTrEmailIdFromUrl(sourceURL)), ...trackEmailId}
  //     }).then();
  //   }

  //   return of(isTokenPresented);
  // }

  canActivateOrLoad(): Observable<boolean> {
    const isTokenPresented = this.getTokenFromStorage();

    if (!isTokenPresented) {
      const sourceURL = this.location.path(true);
    
      // Extract tr-email-id if available (Existing functionality)
      let emailId = getTrEmailIdFromUrl(sourceURL);
      let trackEmailId = isRealValue(emailId) ? { 'tr-email-id': emailId } : null;
  
      // Extract the redirect URL from query params (New functionality)
      const queryParams = new URLSearchParams(sourceURL);
      const encodedRedirect = queryParams.get('redirect'); // The old URL (Base64 encoded)
      
      // Determine final redirect URL
      let redirectTo;
      if (encodedRedirect) {
        let standardBase64 = encodedRedirect?.replace(/-/g, "+")?.replace(/_/g, "/");
        redirectTo = atob(standardBase64); // Decode it if present
        emailId = getTrEmailIdFromUrl(redirectTo);
        trackEmailId = isRealValue(emailId) ? { 'tr-email-id': emailId } : null;
      } else {
        redirectTo = sourceURL; 
      }
  
      // Navigate to login with appropriate redirect
      this.router.navigate(['/auth/login'], {
        queryParams: {redirectTo: redirectTo ? btoa(cutTrEmailIdFromUrl(redirectTo)) : null, ...trackEmailId}
      }).then();
    }

    return of(isTokenPresented);
  }
  

  private getTokenFromStorage(): boolean {
    this.token = this.storage.getItem(storageKeys.token) as string;

    return isNonEmptyString(this.token);
  }
}
